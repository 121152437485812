/*
 * @Author: Rikka
 * @Date: 2022-11-12 12:20:07
 * @LastEditTime: 2022-11-12 14:30:24
 * @LastEditors: Rikka
 * @Description:
 * @FilePath: \stark\project\sneaky\src\main.ts
 */

import("./bootstrap");
